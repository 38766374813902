.Navigation {
  &__Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 7px 5px -5px #ccc;
    padding: 0 15px;
  }

  &__Title {
    width: 100%;
    text-align: center;
    font-weight: 100;
  }
}
