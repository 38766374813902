.HomePage {
  padding: 0 15px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: url('../../assets/bg-home.png') center center no-repeat;
  background-size: cover;

  &__Btn {
    background: rgba(81, 81, 81, 0.5);
    color: #fffc;
    text-decoration: none;
    padding: 15px;
    display: block;
    text-align: center;
    margin: 10px;
    border-radius: 5px;
    font-weight: 100;
  }

  &__Footer {
    color: #ffffffdb;
    text-align: center;
    font-size: .8em;
  }
}
