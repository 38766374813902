.DeliveryPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Navigation {
    background: #fff;
    position: relative;
  }

  &__Frame {
    width: 100%;
    height: 100%;
    border: none;
  }
}
